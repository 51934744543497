import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { buttonPaddingX } from './styles'

const createDotSize = (size) => ({ width: size, height: size })

const styles = (theme) => {
  return createStyles({
    dot: {
      ...createDotSize('0.5rem'),
      borderRadius: '50%',
      position: 'absolute',
      left: buttonPaddingX,
      [theme.breakpoints.up('md')]: {
        ...createDotSize('0.75rem'),
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.contrastText,
    },
    deselected: {
      backgroundColor: theme.palette.primary.main,
    },
  })
}

const Dot = ({ classes, isSelected }) => {
  const extraClass = isSelected ? classes.selected : classes.deselected
  return <div className={`${classes.dot} ${extraClass}`} />
}

export default withStyles(styles)(Dot)
