import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'
import { Link } from 'gatsby'

import Location from './Location'
import rightArrowIcon from './right-arrow.png'
import { getWidth } from '../../../../utils/flex'
import { getJobTypeName } from '../../../../utils/types'

const styles = (theme) => {
  const borderRadius = '0.375rem'
  const flexBreakpointUp = theme.breakpoints.up('md')
  const flexBreakpointUpLg = theme.breakpoints.up('lg')
  return createStyles({
    item: {
      listStyle: 'none',
      width: getWidth(2, '0.625rem'),
      marginBottom: '0.75rem',
      backgroundColor: '#2B2B2B',
      paddingBottom: '0.9269rem',
      borderRadius,
      marginRight: '5px',
      [flexBreakpointUp]: {
        width: getWidth(4, '1rem'),
        paddingBottom: '0.6875rem',
      },
      [flexBreakpointUpLg]: {
        width: getWidth(4, '1.375rem'),
      },
    },
    link: {
      textDecoration: 'none',
      color: 'black',
    },
    header: {
      backgroundColor: '#231F20',
      marginBottom: '1.625rem',
      padding: '0.625rem 0.625rem 0.75rem',
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      [flexBreakpointUp]: {
        marginBottom: '2rem',
      },
      [flexBreakpointUpLg]: {
        padding: '0.4375rem 0',
        marginBottom: '2.625rem',
      },
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: 'transparent',
    },
    headerText: {
      color: '#F0EDEF',
      fontSize: '0.625rem',
      textTransform: 'uppercase',
      fontWeight: 600,
      [flexBreakpointUp]: {
        fontSize: '0.75rem',
      },
      [flexBreakpointUpLg]: {
        fontSize: '1rem',
        lineHeight: '1.32rem',
      },
    },
    footer: {
      marginTop: 'auto',
    },
    title: {
      color: '#F0EDEF',
      margin: '0 0.125rem 2.25rem 1rem',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.155rem',
      [flexBreakpointUp]: {
        fontSize: '1rem',
        lineHeight: '1.32rem',
        margin: '0 0.625rem 6.9375rem 1.1875rem',
      },
      [flexBreakpointUpLg]: {
        fontSize: '1.375rem',
        lineHeight: '1.815rem',
        margin: '0 0 5.4375rem 1.75rem',
      },
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '0.9269rem',
      [flexBreakpointUp]: {
        paddingRight: '0.6875rem',
      },
      [flexBreakpointUpLg]: {
        paddingRight: '1.0156rem',
      },
    },
    rightArrowIcon: {
      width: '0.625rem',
      height: '0.6875rem',
      [flexBreakpointUp]: {
        width: '0.9375rem',
        height: '1rem',
      },
    },
  })
}

const Job = ({
  classes,
  types,
  childMarkdownRemark: {
    fields: { slug },
    frontmatter: { title, location, type },
  },
}) => {
  const jobTypeName = getJobTypeName(type, types)
  return (
    <li className={classes.item}>
      <Link to={slug} className={classes.link}>
        <div className={classes.body}>
          <div>
            <div className={classes.header}>
              <Typography className={classes.headerText} align="center">
                {jobTypeName}
              </Typography>
            </div>
            <Typography className={classes.title} variant="h3">
              {title}
            </Typography>
          </div>
          <div className={classes.footer}>
            <Location location={location} />
            <div className={classes.iconContainer}>
              <img
                src={rightArrowIcon}
                alt="Right Arrow"
                className={classes.rightArrowIcon}
              />
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default withStyles(styles)(Job)
