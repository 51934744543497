import React from 'react'
import { Typography, Button, createStyles, withStyles } from '@material-ui/core'

import Dot from './Dot'
import { buttonPaddingX } from './styles'

const styles = (theme) => {
  const { primary: selectedColor } = theme.palette
  const flexBreakpointUp = theme.breakpoints.up('md')
  const flexBreakpointUpLg = theme.breakpoints.up('lg')
  const selectedStyles = {
    backgroundColor: selectedColor.main,
    border: `1px solid ${selectedColor.main}`,
  }
  return createStyles({
    item: {
      padding: `0.4375rem ${buttonPaddingX}`,
      borderRadius: '1rem',
      boxSizing: 'border-box',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        margin: '3px 3px',
      },
      [flexBreakpointUpLg]: {
        padding: '0.6875rem 0',
        borderRadius: '2rem',
      },
    },
    selected: {
      ...selectedStyles,
      '&:hover': {
        ...selectedStyles,
      },
    },
    deselected: {
      border: '1px solid gray',
    },
    text: {
      fontSize: '0.625rem',
      [flexBreakpointUp]: {
        fontSize: '0.875rem',
      },
      [flexBreakpointUpLg]: {
        fontSize: '1rem',
      },
    },
    deselectedText: {
      fontWeight: 600,
      color: '#F0EDEF',
    },
    selectedText: {
      color: selectedColor.contrastText,
      fontWeight: 800,
    },
  })
}

const Item = ({ classes, id, name, selectedType, setSelectedType }) => {
  const isSelected = selectedType === id

  const getButtonClasses = () => {
    const extraClass = isSelected ? classes.selected : classes.deselected
    return `${classes.item} ${extraClass}`
  }

  const getTextClasses = () => {
    const extraClass = isSelected
      ? classes.selectedText
      : classes.deselectedText
    return `${classes.text} ${extraClass}`
  }

  const handleClick = () => setSelectedType(id)

  return (
    <Button className={getButtonClasses()} onClick={handleClick}>
      <Dot isSelected={isSelected} />
      <Typography variant="button" className={getTextClasses()}>
        {name}
      </Typography>
    </Button>
  )
}

export default withStyles(styles)(Item)
