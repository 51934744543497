import React from 'react'
import Head from 'react-helmet'
import { createStyles, withStyles, Container } from '@material-ui/core'

import Hero from './Hero'
import Instructions from './Instructions'
import Jobs from './Jobs'
import { getContainerStyles } from '../components/ContentContainer'
import Layout from '../components/Layout'

const styles = (theme) => {
  const baseContainerStyles = getContainerStyles(theme)
  return createStyles({
    container: {
      ...baseContainerStyles.container,
      paddingLeft: '2.4375rem',
      paddingRight: '2rem',
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
  })
}

const Careers = ({ data, classes }) => (
  <Layout>
    <Head>
      <title>Careers</title>
    </Head>
    <Container className={classes.container}>
      <Hero />
      <Instructions />
      <Jobs data={data} />
    </Container>
  </Layout>
)

export default withStyles(styles)(Careers)
