import React from 'react'
import { Link as BaseLink, createStyles, withStyles } from '@material-ui/core'

const styles = () => {
  return createStyles({
    link: {
      fontWeight: 'bold',
    },
  })
}

const Link = ({ classes, ...other }) => {
  return <BaseLink className={classes.link} color="primary" {...other} />
}

export default withStyles(styles)(Link)
