const getIfTypesAreEqual = (typeId) => (jobType) =>
  typeId === jobType.childMarkdownRemark.fields.slug

const findJobType = (id, jobTypes) => jobTypes.find(getIfTypesAreEqual(id))

export const getJobTypeName = (id, types) => {
  const {
    childMarkdownRemark: {
      frontmatter: { name },
    },
  } = findJobType(id, types)
  return name
}
