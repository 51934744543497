import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import Link from '../components/Link'

const styles = (theme) => {
  return createStyles({
    section: {
      marginBottom: '4.9375rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '10.375rem',
      },
    },
    title: {
      marginBottom: '1rem',
    },
  })
}

const Instructions = ({ classes }) => {
  return (
    <section className={classes.section}>
      <Typography className={classes.title} variant="h2">
        How to Apply
      </Typography>
      <Typography paragraph>
        Send an email with your resume to{' '}
        <Link href="mailto:support@avocadofinance.io" color="primary">
          support@avocadofinance.io
        </Link>
        .
      </Typography>
    </section>
  )
}

export default withStyles(styles)(Instructions)
