import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import { getHeroStyles } from '../../styles/hero'
import PuzzleSvg from './puzzle.svg'

const styles = (theme) => {
  const heroStyles = getHeroStyles()
  const flexBreakpointUp = theme.breakpoints.up('md')
  const flexBreakpointLg = theme.breakpoints.up('lg')
  return createStyles({
    hero: {
      ...heroStyles,
      marginBottom: '6.4375rem',
      [flexBreakpointUp]: {
        marginBottom: '7.5625rem',
      },
    },
    body: {
      [flexBreakpointUp]: {
        display: 'flex',
      },
      [flexBreakpointLg]: {
        justifyContent: 'space-between',
      },
    },
    image: {
      marginBottom: '2.25rem',
      position: 'relative',
      left: '-9%',
      [flexBreakpointUp]: {
        display: 'none',
      },
    },
    title: {
      marginBottom: '1rem',
      fontWeight: 700,
      lineHeight: '2.805rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    description: {
      [flexBreakpointUp]: {
        marginTop: '1rem',
        marginRight: '2.5rem',
        width: '100%',
      },
      [flexBreakpointLg]: {
        maxWidth: '35.75rem',
      },
    },
    imageColumn: {
      display: 'none',
      [flexBreakpointUp]: {
        display: 'block',
        width: '20.5625rem',
      },
      [theme.breakpoints.up('md')]: {
        display: 'block',
        position: 'relative',
        left: '-35px',
        top: '-100px',
        width: '20.5625rem',
      },
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
        left: '-185px',
        top: '-100px',
      }
    },
  })
}


const Hero = ({ classes }) => {
  return (
    <section className={classes.hero}>
      <object className={classes.image} data={PuzzleSvg} type="image/svg+xml">
        <img className={classes.image} src={PuzzleSvg} />
      </object>
      <Typography className={classes.title} variant="h1">
        We are Hiring!
      </Typography>
      <div className={classes.body}>
        <Typography className={classes.description}>
          We are looking for passionate and talented professionals who are
          excited to be part of our mission. We are constantly looking to add to
          our team.
        </Typography>
        <object className={classes.imageColumn} data={PuzzleSvg} type="image/svg+xml">
          <img className={classes.imageColumn} src={PuzzleSvg} />
        </object>
      </div>
    </section>
  )
}

export default withStyles(styles)(Hero)
