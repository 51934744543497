import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import icon from './location.png'

const styles = (theme) => {
  const flexBreakpointUp = theme.breakpoints.up('sm')
  const flexBreakpointUpLg = theme.breakpoints.up('lg')
  return createStyles({
    location: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.1875rem',
      paddingLeft: '0.625rem',
      [flexBreakpointUp]: {
        paddingLeft: '1.25rem',
      },
      [flexBreakpointUpLg]: {
        paddingLeft: '1.5rem',
      },
    },
    icon: {
      width: '1rem',
      height: '0.955rem',
      marginRight: '0.4375rem',
      [flexBreakpointUp]: {
        width: '1.2rem',
        height: '1.25rem',
        marginRight: '0.625rem',
      },
    },
    text: {
      fontSize: '0.625rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      [flexBreakpointUp]: {
        fontSize: '0.75rem',
      },
    },
  })
}

const Location = ({ classes, location }) => {
  return (
    <div className={classes.location}>
      <img src={icon} alt="Location" className={classes.icon} />
      <Typography className={classes.text}>{location}</Typography>
    </div>
  )
}

export default withStyles(styles)(Location)
