import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Job from './Job'

const styles = () => {
  return createStyles({
    list: {
      paddingLeft: 0,
      display: 'flex',
      // justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  })
}

const List = ({ classes, jobs, types }) => {
  const renderJob = (jobData) => (
    <Job
      {...jobData}
      key={jobData.childMarkdownRemark.fields.slug}
      types={types}
    />
  )
  const renderedJobs = jobs.map(renderJob)
  return <ul className={classes.list}>{renderedJobs}</ul>
}

export default withStyles(styles)(List)
