import React, { useState } from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import Filters from './Filters'
import List from './List'

const styles = (theme) => {
  return createStyles({
    title: {
      marginBottom: '2.8125rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '3.625rem',
      },
    },
  })
}

const Jobs = ({
  classes,
  data: {
    jobs: { nodes: jobs },
    types: { nodes: types },
  },
}) => {
  const [type, setType] = useState(null)

  const getIfJobHasType = (job) =>
    job.childMarkdownRemark.frontmatter.type === type

  const filterJobs = () => {
    if (!type) return jobs
    return jobs.filter(getIfJobHasType)
  }

  const filteredJobs = filterJobs()

  return (
    <section>
      <Typography variant="h2" className={classes.title}>
        Explore Roles
      </Typography>
      <Filters selectedType={type} setSelectedType={setType} types={types} />
      <List jobs={filteredJobs} types={types} />
    </section>
  )
}

export default withStyles(styles)(Jobs)
