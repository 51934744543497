import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Item from './Item'

const styles = (theme) => {
  return createStyles({
    list: {
      paddingLeft: 0,
      marginBottom: '3.2388rem',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '0.7425rem 0.625rem',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '1.2888rem 0.3713rem',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gap: '1.1756rem 0.4375rem',
      },
    },
  })
}

const Filters = ({ classes, types, selectedType, setSelectedType }) => {
  const renderType = (typeData) => {
    const {
      fields: { slug: id },
      frontmatter: { name },
    } = typeData.childMarkdownRemark
    return (
      <Item
        {...typeData}
        key={id}
        id={id}
        name={name}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
    )
  }
  const renderedTypes = types.map(renderType)
  return (
    <ul className={classes.list}>
      <Item
        id={null}
        name="All Jobs"
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
      {renderedTypes}
    </ul>
  )
}

export default withStyles(styles)(Filters)
